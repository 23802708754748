<template>
  <div class="tab-box">
    <div></div>
    <div v-for="(item, index) in tabList" :key="index" class="item">{{ item.title }}</div>
    <div></div>
  </div>
</template>
<script>
export default {
  name: "Banner",

  props: {},
  data() {
    return {
      build: require("@/assets/images/customer/banner.png"),
      tabList: [
        {
          name: "telecom",
          title: "联通运营商",
        },
        {
          name: "financial",
          title: "金融保险",
        },
        {
          name: "education",
          title: "教育",
        },
        {
          name: "Car",
          title: "汽车出行",
        },
        {
          name: "enterprise",
          title: "政企",
        },
        {
          name: "Internet",
          title: "互联网",
        },
      ],
    };
  },
  create() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.tab-box {
  transform: translateY(-28px);
  margin: 0 300px;
  // margin: 100px 400px;
  display: flex;
  box-shadow: 0px 4px 32px 0px rgba(18, 43, 108, 0.18);
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
  position: relative;
  background-color: #fff;
  /*border-radius: 0 0 30px 0;*/
  /*background-color: #000000;*/
  // z-index: -1;
  z-index: 1;
  height: 60px;
  div {
    display: inline-block;
    flex: 1;
    background-color: #fff;
    padding: 0px 0px;
    /*position: relative;*/
    z-index: 333;
    height: 73px;
    line-height: 73px;
    position: relative;
    font-size: 0.25rem;
    font-family: SimHei;
    cursor: pointer;

    color: #666666;
    // &:nth-child(2) {
    //   color: #1f75e1;
    // }

    &::after {
      position: absolute;
      content: "";
      right: 0;

      margin: 0 auto;
      bottom: 17px;

      width: 0.0125rem;
      height: 0.55rem;
      background: #99a6cc;
      opacity: 0.38;
      z-index: 333;
    }
    &:nth-child(1)::after {
      width: 0;
    }
    &:nth-child(7)::after {
      width: 0;
    }
    &:nth-child(8)::after {
      width: 0;
    }
  }
    .item:hover{
     color: #1f75e1;
    }
  div:nth-child(1) {
    /*position: absolute;*/
    /*width: 100%;*/
    /*height: 100%;*/
    flex: 0.3;
    /*position: absolute;*/
    /*z-index: 333;*/
    transform: skewX(30deg);
    background-color: #fff;
    /*transform: skewX(30deg);*/
    border-radius: 0 0 0 30px;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: 3;
    top: 0px;
    left: -63px;
    transform: skewX(30deg);
    width: 50px;
    height: 25px;
    background: linear-gradient(
      to bottom right,
      transparent 0%,
      transparent 49.9%,
      #c0c4cc 50%,
      #909399 100%
    );
  }
  div:last-child {
    flex: 0.3;
    background-color: #fff;
    transform: skewX(-30deg);
    border-radius: 0 0 30px 0;
  }
  &::after {
    content: "";
    position: absolute;
    z-index: 3;
    top: 0px;
    right: -63px;
    width: 50px;
    height: 25px;
    transform: skewX(-30deg);
    background: linear-gradient(
      to bottom left,
      transparent 0%,
      transparent 49.9%,
      #c0c4cc 50%,
      #909399 100%
    );
  }
}

@media screen and (max-width: 1500px) {
  .tab-box {
    margin: 0 3.75rem;
  }
  .tab-box div::after {
    right: 1px;
    bottom: 25px;
  }
}
@media screen and (max-width: 750px) {
  .tab-box {
    margin: 0 1.5rem;
  }
  .tab-box div {
    height: 63px;
    line-height: 63px;
  }
  .tab-box::before {
    left: -60px;
  }
  .tab-box::after {
    right: -60px;
  }
}

@media screen and (max-width: 414px) {
  .tab-box div {
    line-height: 30px !important;
  }
  .tab-box div[data-v-28118ff6]:nth-child(1) {
    transform: skewX(23deg) !important;
  }

  .tab-box div[data-v-28118ff6]:last-child {
    transform: skewX(338deg) !important;
  }
  .tab-box::before {
    left: -56px;
  }
  .tab-box::after {
    right: -56px;
  }
}
</style>
