<template>
  <div class="Customer">
    <Banner></Banner>
    <Telecom></Telecom>
    <Beanding></Beanding>
  </div>
</template>

<script>
import Banner from "@/components/customer/Banner.vue";
import Telecom from "@/components/customer/Telecom.vue";
import Beanding from "@/components/customer/Beanding.vue";

export default {
  name: "Customer",
  data() {
    return {};
  },
  components: {
    Banner,
    Telecom,
    Beanding,
  },
  props: {},

  create() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped></style>
