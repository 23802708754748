<template>
  <div class="views-customer-Telecom ">
    <div class="CallOut-title ">
      <div class="line-content">
        <span class="line-item-left"></span>
        <h2 class="line-call">联通运营商</h2>
        <span class="line-item"></span>
      </div>
      <h3 class="line-title">TELECOM OPERATOR</h3>
    </div>
    <div class="customer wow fadeInUp"  data-wow-duration="0.8s"
      data-wow-delay="0.4s">
      <div
        class="telecom "
        v-for="(item, index) in list"
        :key="index"
       @mouseover="onMouseover(index)"
      >
        <div class="telecom-img">
          <img v-lazy="item.img" alt="" />
        </div>
        <div class="telecom-text">
          <div class="telecom-content">
            <div class="telecom-left ">
              <div class="left-item">企业简介:</div>
              <div class="right-item">{{ item.qiye }}</div>
            </div>
            <div class="telecom-right ">
              <div class="left-item">合作详情:</div>
              <div class="right-item">{{ item.hezuo }}</div>
            </div>
            <div class="telecom-buttom ">
              <div class="user">使用产品:</div>
              <div
                v-for="(iconItem, iconIndex) in item.icon"
                :key="iconIndex"
                class="table"
              >
                <div class="images ">
                  <img v-lazy="iconItem.imgSrc" alt="" />
                </div>
                <div class="call-heart">
                  {{ iconItem.call }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="telecom-samll-img"
          :class="{ active: index === activeIndex }"
        ></div>
      </div>
      <div class="back-Img"></div>
    </div>
  </div>
</template>
<script>
import WOW from "wowjs";
export default {
  name: "Telecom",

  props: {},
  data() {
    return {
      activeIndex: 0,
      list: [
        {
          img: require("@/assets/images/customer/LOGO_2.png"), //详情
          qiye:
            "中国唯一一家在纽约、香港、上海三地同时上市的联通运营企业，连续多年入选“世界500强企业”。",
          hezuo:
            "航动科技与中国联通合作长达五年，为其全国18省市提供呼叫中心业务支撑。除了一般的外呼服务营销，航动科技为联通定制权益增值业务，联合打造基于用户关系维护的客户微生态运营平台。",
          icon: [
            {
              imgSrc: require("@/assets/images/customer/icon-5.png"),
              call: "智能外呼系统",
            },
            {
              imgSrc: require("@/assets/images/customer/icon-7.png"),
              call: "智能质检",
            },
            {
              imgSrc: require("@/assets/images/customer/icon-3.png"),
              call: "数字大屏",
            },
            {
              imgSrc: require("@/assets/images/customer/icon-4.png"),
              call: "智能权益运营系统",
            },
          ],
        },
        {
          img: require("@/assets/images/customer/LOGO_1.png"),
          qiye:
            "中国特大型国有通信企业、上海世博会全球合作伙伴，连续多年入选“界500强企业”。",
          hezuo:
            "2020年航动科技为中国电信山东分公司提供全媒体呼叫中心服务，携手打造DICT合作生态圈； 2017年航动科技为山东烟台电信89000民生热线呼叫中心建设项目。",

          icon: [
            {
              imgSrc: require("@/assets/images/customer/icon-2.png"),
              call: "全媒体呼叫中心",
            },
            {
              imgSrc: require("@/assets/images/customer/icon-8.png"),
              call: "智能助手",
            },
            // {
            //   imgSrc: require("@/assets/images/customer/icon-1.png"),
            //   call: "BPO业务",
            // },
          ],
        },
        {
          img: require("@/assets/images/customer/LOGO_3.png"),
          qiye:
            "中国移动通信集团公司是中国规模最大的移动通信运营商，主要经营移动语音、数据、IP电放和多媒体业务。",
          hezuo:
            "航动科技为中国移动及阳光保险联合定制跨行业呼叫中心支撑方案。通过提供平台、线路、四分屏等产品，及智能文本分析和大数据技术，全方位支撑品牌的服务营销工作，提升客户满意度。",

          icon: [
            {
              imgSrc: require("@/assets/images/customer/icon-6.png"),
              call: "语音机器人",
            },
            {
              imgSrc: require("@/assets/images/customer/icon-8.png"),
              call: "智能助手",
            },
          ],
        },
      ],
    };
  },
  create() {},
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },
  methods: {
    onMouseover(index) {
      this.activeIndex = index;
      console.log(index);
    },
  },
};
</script>
<style lang="less" scoped>
.views-customer-Telecom {
  margin-top: 1.0375rem;
  margin-bottom: 2.4625rem;
  position: relative;
  .CallOut-title {
    margin-bottom: 47px;
    .line-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 63%;
      margin: 0 auto;
      .line-call {
        font-size: 0.5rem;
        font-family: SimHei;
        color: #333333;
        white-space: nowrap;
        letter-spacing: 7px;
      }
      .line-item {
        background: url("../../assets/images/product/line-title-right.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
      .line-item-left {
        background: url("../../assets/images/product/line-title-left.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
    }
    .line-title {
      font-size: 0.25rem;
      font-family: SimHei;
      color: #666666;
      letter-spacing: 3px;
      padding-top: 14px;
    }
  }
  .telecom {
    margin: auto;
    margin-top: 36px;
    width: 67%;
    // padding: 15px 0;
    // padding: 30px 0;
    background: linear-gradient(0deg, #ffffff, #fbfcff);
    box-shadow: 0px 8px 21px 8px rgba(26, 32, 113, 0.1);
    border-radius: 20px;
    margin-bottom: 36px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    .telecom-img {
      padding-left: 0.625rem;
      padding-right: 0.525rem;
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
      img {
      }
    }
    .telecom-text {
      .telecom-content {
        font-size: 0.2rem;
        font-family: SimHei;
        color: #333333;
        padding: 30px 0;

        .telecom-left {
          width: 80%;
          padding-bottom: 30px;
          display: flex;
          position: relative;
          .left-item {
            white-space: nowrap;
          }
          .right-item {
            padding-left: 24px;
            text-align: left;
            color: #999999;
            letter-spacing: 1.5px;
            line-height: 20px;
          }
          &::after {
            content: "";
            /* display: inline-block; */
            height: 1px;
            background: #dce4f0;
            width: 100%;
            /* display: flex; */
            position: absolute;
            left: 0;
            bottom: 15px;
          }
        }
        .telecom-right {
          width: 80%;
          padding-bottom: 30px;
          position: relative;
          display: flex;
          .left-item {
            white-space: nowrap;
          }
          .right-item {
            padding-left: 24px;
            text-align: left;
            color: #999999;
            letter-spacing: 1.5px;
            line-height: 20px;
          }
          &::after {
            content: "";
            /* display: inline-block; */
            height: 1px;
            background: #dce4f0;
            width: 100%;
            /* display: flex; */
            position: absolute;
            left: 0;
            bottom: 15px;
          }
        }
        .telecom-buttom {
          width: 80%;
          display: flex;
          // justify-content: space-between;
          .user {
            white-space: nowrap;
            text-align: left;
            display: flex;
            align-items: center;
          }
          .table {
            // justify-content: space-around;
            display: flex;
            align-items: center;
            // flex-basis: 25%;
            padding-left: 0.3rem;
            padding-right: 0.2rem;
            .call-heart {
              font-size: 0.2rem;
              font-family: SimHei;

              color: #666666;
              padding-left: 8px;
            }
            .images {
              img {
                width: 25px;
                height: 27px;
              }
            }
          }
        }
      }
    }

    .telecom-samll-img {
      position: absolute;
      right: 0;
      bottom: 0;
      background: url("../../assets/images/customer/xiangqing.png") no-repeat;
      height: 0.6375rem;
      width: 1.3625rem;
      background-size: contain;
      cursor: pointer;
      // padding: 30px 0;

      &.active {
        // align-self: flex-end;
        // margin-bottom: -0.425rem;
        background: url("../../assets/images/customer/xiangqing1.png") no-repeat;
        background-size: contain;
        // padding: 30px 0;
      }
    }
  }
  .telecom::after {
    // content: "";
    // display: block;
    // align-self: flex-end;
    // margin-bottom: -34px;
    // background: url("../../assets/images/customer/xiangqing.png") no-repeat;
    // height: 40px;
    // width: 10%;
    // background-size: contain;
    // background-color: red;
    &.active {
      background: red;
      height: 40px;
      width: 10%;
      // background: url("../../assets/images/customer/xiangqing1.png") no-repeat;
      // height: 40px;
      // width: 10%;
      // background-size: contain;
    }
  }
  .customer {
    position: relative;
    overflow: hidden;
    .back-Img {
      position: absolute;
      z-index: -1;
      background: url(../../assets/images/product/VI-1.png) no-repeat;
      background-size: 100%;

      height: 100%;
      width: 70%;
      right: -34%;
      top: -28px;
    }
  }
}
@media screen and (max-width: 1200px) {
  .views-customer-Telecom {
    .telecom {
      .telecom-img {
        img {
          width: 100%;
          height: 100%;
        }
      }
      .telecom-text {
        .telecom-content {
          .telecom-left {
            width: 90%;
          }
          .telecom-right {
            width: 90%;
          }
          .telecom-buttom {
            width: 90%;
          }
        }
      }
    }
    .back-Img {
      transform: scale(0.8);
    }
  }
}
@media screen and (max-width: 992px) {
  .views-customer-Telecom {
    .telecom .telecom-text .telecom-content .telecom-buttom .table {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 750px) {
  .views-customer-Telecom {
    .telecom {
      flex-direction: column;
      width: 90%;
      .telecom-img {
        img {
          width: 50%;
          height: 50%;
        }
      }
      .telecom-text {
        padding-left: 20px;
      }
    }
  }
}

@media screen and (max-width: 680px) {
  .views-customer-Telecom .CallOut-title .line-content {
    .line-item {
      width: 0.75rem;
    }
    .line-item-left {
      width: 0.75rem;
    }
  }
}
@media screen and (max-width: 480px) {
  .views-customer-Telecom {
    .back-Img {
      display: none;
    }
  }
}
</style>
