<template>
  <div class="views-customer-Beanding ">
    <div class="solution-title case-title">
      <div class="line-content head-top">
        <span class="line-item-left"></span>
        <h2 class="line-call">品牌信赖-更多合作伙伴</h2>
        <span class="line-item"></span>
      </div>
      <p class="line-title">CO-BRANDING</p>
      <div class="customer-img">
        <div class="customer-content ">
          <div v-for="(item, index) in imgList" :key="index" class="img-item">
            <img v-lazy="item.srcImg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WOW from "wowjs";
export default {
  name: "Beanding",

  props: {},
  data() {
    return {
      imgList: [
        { srcImg: require("@/assets/images/customer/LOGO_91.png") },
        { srcImg: require("@/assets/images/customer/LOGO_79.png") },
        { srcImg: require("@/assets/images/customer/LOGO_34.png") },
        { srcImg: require("@/assets/images/customer/LOGO_64.png") },
        { srcImg: require("@/assets/images/customer/LOGO_0.png") },
        { srcImg: require("@/assets/images/customer/LOGO_53.png") },
        { srcImg: require("@/assets/images/customer/LOGO_13.png") },
        { srcImg: require("@/assets/images/customer/LOGO_48.png") },
        { srcImg: require("@/assets/images/customer/LOGO_33.png") },
        { srcImg: require("@/assets/images/customer/LOGO_80.png") },
        { srcImg: require("@/assets/images/customer/LOGO.png") },
      ],
    };
  },
  create() {},
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.views-customer-Beanding {
  width: 100%;
  .solution-title {
    background: url(../../assets/images/solution/back.png) no-repeat;
    background-size: contain;

    .customer-img {
      width: 75%;
      margin: 0 auto;
      box-sizing: border-box;
      .customer-content {
        padding: 0.5rem 0.625rem;
        display: flex;
        flex-wrap: wrap;

        border-radius: 5px;
        justify-content: space-around;
        justify-content: flex-start;
        .img-item {
          overflow: hidden;
          border-radius: 3px;
          cursor: pointer;
          transition: all 0.3s ease 0s;
          width: 25%;
          padding: 0.2375rem 0.35rem;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .case-title {
    .line-content {
      padding-top: 0.6125rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 63%;
      margin: 0 auto;
      .line-call {
        font-size: 0.375rem;
        font-family: SimHei;
        color: #fff;
        white-space: nowrap;
        letter-spacing: 7px;
      }
      .line-item {
        background: url("../../assets/images/product/line-right-while.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
      .line-item-left {
        background: url("../../assets/images/product/line-left-while.png")
          no-repeat 100% 100%;
        height: 2px;
        width: 4.6375rem;
        background-size: contain;
      }
    }
    .line-title {
      padding-top: 18px;
      font-size: 0.3rem;
      font-family: SimHei;
      color: #fff;
      letter-spacing: 3px;
    }
  }
}

@media screen and (max-width: 750px) {
  .views-customer-Beanding {
    .customer-img {
      width: 90% !important;
    }
    .customer-content {
      background: #fff;
      box-shadow: 0px 7px 20px 0px rgba(18, 29, 87, 0.2);
      border-radius: 10px;
    }
  }
}
@media screen and (max-width: 680px) {
  .views-customer-Beanding {
    .customer-img {
      .img-item {
        width: 33% !important;
        padding: 5px !important;
      }
    }
    .line-item {
      width: 0.75rem !important;
    }
    .line-item-left {
      width: 0.75rem !important;
    }
  }
}
@media screen and (max-width: 480px) {
  .customer-img {
    .img-item {
      width: 50% !important;
      padding: 10px !important;
    }
  }
}
@media screen and (max-width: 480px) {
  .line-title {
    padding-bottom: 0 !important;
  }
}
</style>
