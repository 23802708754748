<template>
  <div class="views-customer-Banner">
    <div class="Banner-header ">
      <!-- 大图 -->
      <div class="Banner-title">
        <img v-lazy="BannerTitle" />
      </div>
      <div class="Banner-image">
        <img v-lazy="build" />
      </div>
      <BannerTtab />
      <div>
        <!-- <div class="foote-item">
          <ul>
            <li v-for="(item, index) in tabList" :key="index">
              {{ item.title }}
            </li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import BannerTtab from "@/components/customer/Banner-tab.vue";
import WOW from "wowjs";
export default {
  name: "Banner",
  components: {
    BannerTtab,
  },

  props: {},
  data() {
    return {
      BannerTitle: require("@/assets/images/customer/banner-title.png"),
      build: require("@/assets/images/customer/banner.png"),
      // tabList: [
      //   {
      //     name: "telecom",
      //     title: "联通运营商",
      //   },
      //   {
      //     name: "financial",
      //     title: "金融保险",
      //   },
      //   {
      //     name: "education",
      //     title: "教育",
      //   },
      //   {
      //     name: "Car",
      //     title: "汽车出行",
      //   },
      //   {
      //     name: "enterprise",
      //     title: "政企",
      //   },
      //   {
      //     name: "Internet",
      //     title: "互联网",
      //   },
      // ],
    };
  },
  create() {},
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW.WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        mobile: true,
        live: false,
      });
      wow.init();
    });
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.views-customer-Banner {
  // margin-top: 90px;
  .Banner-header {
    position: relative;
    .Banner-title {
      position: absolute;
      z-index: 1;
      left: 19%;
      top: 33%;
      img {
        width: 100%;
      }
    }
    .Banner-image {
      width: 100%;
      img {
        width: 100%;
        height: 490px;
      }
    }

    .foote-item {
      position: absolute;
      left: 20%;
      bottom: -36px;

      width: 67%;

      // box-shadow: 0px 4px 32px 0px rgba(18, 43, 108, 0.18);
    }
    ul {
      display: flex;
      flex: 1;
      justify-content: space-around;
      align-items: center;
      // background: #ffffff;
      // box-shadow: 0px 4px 32px 0px rgba(18, 43, 108, 0.18);
      height: 99px;
      position: relative;
      z-index: 1;
      bottom: -48px;
      background: url(../../assets/images/customer/banner-B.png) no-repeat 100%
        100%;
      background-size: cover;

      li {
        position: relative;
        text-align: center;
        font-size: 0.25rem;
        font-family: SimHei;

        color: #6276ab;
        top: -20%;
      }
      li::after {
        position: absolute;
        content: "";
        right: -55px;

        margin: 0 auto;
        bottom: -12px;

        width: 0.0125rem;
        height: 0.55rem;
        background: #99a6cc;
        opacity: 0.38;
      }
    }
    .foote-item::after {
      content: "";
      position: absolute;
      background: url(../../assets/images/customer/banner-L.png) no-repeat 100%
        100%;
      background-size: cover;
      height: 120px;
      width: 133px;
      left: 0px;
      top: 26px;
    }
    .foote-item::before {
      content: "";
      position: absolute;
      background: url(../../assets/images/customer/banner-R.png) no-repeat 100%
        100%;
      background-size: cover;
      height: 117px;
      width: 100px;
      right: -7px;
      top: 27px;
    }
    li:last-child::after {
      width: 0;
    }
  }
}

@media screen and (max-width: 992px) {
  .views-customer-Banner {
    .Banner-header {
      .Banner-image {
        img {
          height: 426px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .views-customer-Banner {
    .Banner-header {
      .Banner-image {
        img {
          height: 350px;
        }
      }
    }
  }
}
</style>
